export const cssStyling: string = `
  pre, pre.highlight {
    background-color: #f2f2f2;
    padding: 1em;
  }

  body a[href*='help.form.io'] {
    display: none !important;
  }

  .clickable-row {
    cursor: pointer;
  }

  .builder-component-componentGroup fieldset > .fieldset-body > div[ref$='-container'] {
    padding: 0;
  }

  .builder-component-componentGroup > .component-btn-group {
    margin-right: -20px;
    margin-top: 0;
  }

  .field-wrapper div[ref='radioGroup'] {
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    line-height: 1.5;
  }

  .formio-component fieldset > legend {
    color: #05A4D8;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 1.3em;
  }

  .formio-component fieldset > hr {
    margin-top: 0;
  }

  .formio-dialog .component-edit-container {
    min-height: 512px;
  }
`;
